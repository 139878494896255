/** @jsx jsx */
import { jsx } from "theme-ui"
import { graphql, Link } from "gatsby"
import { Helmet } from 'react-helmet'
import { GatsbyImage } from "gatsby-plugin-image"
import { RiArrowRightSLine } from "@react-icons/all-files/ri/RiArrowRightSLine"
import { RiFacebookFill } from "@react-icons/all-files/ri/RiFacebookFill"
import { RiTwitterFill, } from "@react-icons/all-files/ri/RiTwitterFill"
import { RiGroup2Fill, } from "@react-icons/all-files/ri/RiGroup2Fill"
import { RiDraftFill, } from "@react-icons/all-files/ri/RiDraftFill"
import { RiAwardFill } from "@react-icons/all-files/ri/RiAwardFill"
import { RiHandHeartFill } from "@react-icons/all-files/ri/RiHandHeartFill"
import Layout from "../components/layout"
import BlogListHome from "../components/blog-list-home"
import Seo from "../components/seo"
import Icons from "../util/socialmedia.json"

export const pageQuery = graphql`
  query HomeQuery($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        title
        description
        titleAlt
        tagline
        featuredImage {
          childImageSharp {
            gatsbyImageData(
              layout: CONSTRAINED, 
              width: 585, 
              height: 439
            )
          }
        }
        cta {
          ctaText
          ctaLink
        }
      }
    }
    posts: allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { template: { eq: "blog-post" } } }
      limit: 12
    ) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            path
            title
            featuredImage {
              childImageSharp {
                gatsbyImageData(
                  layout: FULL_WIDTH
                  breakpoints: [250, 345, 585]
                  placeholder: DOMINANT_COLOR
                  quality: 90
                )
              }
            }
          }
        }
      }
    }
    site {
      siteMetadata {
        title
      }
    }
  }
`

const HomePage = ({ data }) => {
  const { markdownRemark, posts, site } = data // data.markdownRemark holds your post data
  const { frontmatter, html } = markdownRemark
  const url = typeof window !== 'undefined' ? window.location.href : '';
  const Image = frontmatter.featuredImage
    ? frontmatter.featuredImage.childImageSharp.gatsbyImageData
    : ""    
  const sIcons = Icons.socialIcons.map((icons, index) => {
    return (
      <div key={"social icons" + index}>
        {icons.icon === "facebook" ? (
          <Link to={icons.url} rel="noopener noreferrer" target="_blank" area-label="Facebook">
            <RiFacebookFill alt='Facebook' />
          </Link>
        ) : (
          ""
        )}
        {icons.icon === "twitter" ? (
          <Link to={icons.url} rel="noopener noreferrer" target="_blank" area-label="Twitter">
            <RiTwitterFill alt='Twitter' />
          </Link>
        ) : (
          ""
        )}
        {icons.icon === "newsletter" ? (
          <Link to={icons.url} rel="noopener noreferrer" target="_blank" area-label="newsletter">
            <RiGroup2Fill alt='newsletter' />
          </Link>
        ) : (
          ""
        )}
        {icons.icon === "in-college" ? (
          <Link to={icons.url} rel="noopener noreferrer" target="_blank" target="_blank" area-label="in-college">
            <RiDraftFill alt='in-college' />
          </Link>
        ) : (
          ""
        )}
        {icons.icon === "graduated" ? (
          <Link to={icons.url} rel="noopener noreferrer" target="_blank" area-label="graduated">
            <RiAwardFill alt='graduated' />
          </Link>
        ) : (
          ""
        )}        
        {icons.icon === "dating" ? (
          <Link to={icons.url} rel="noopener noreferrer" target="_blank" area-label="dating">
            <RiHandHeartFill alt='dating' />
          </Link>
        ) : (
          ""
        )}
      </div>
    )
  })
  return (
    <Layout>
      <Seo
        title={frontmatter.title}
        description={frontmatter.title + " " + site.siteMetadata.title}
        image={Image}
        url={url}
      />
      <Helmet>
        <link rel="canonical" href={url} />
        <meta property="og:url" content={url} />
        <meta property="og:title" content={frontmatter.title} />
        <meta property="og:description" content={frontmatter.description} />
        <meta name="twitter:image:alt" content={frontmatter.title} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="facebook-domain-verification" content="f4o2mpr6x5fh7zvat4ul1i1tpl6gro" />
        <meta name="ahrefs-site-verification" content="437597f72db24bb43ca60ab9b045e654d78d20d1f1220731d81b97dcc1efab3b" />
        <meta property="twitter:title" content={frontmatter.title} />
        <meta property="twitter:description" content={frontmatter.description} />
      </Helmet>
      <div className="home-banner grids col-1 sm-2">
        <div>
          <h1>{frontmatter.titleAlt}</h1>
          <p
            className="tagline"
            sx={{
              color: "muted",
            }}
          >
            {frontmatter.tagline}
          </p>
          <div
            className="description"
            dangerouslySetInnerHTML={{ __html: html }}
          />
          <Link
            to={frontmatter.cta.ctaLink}
            className="button"
            sx={{
              variant: "variants.button",
            }}
          >
            {frontmatter.cta.ctaText}
            <span className="icon -right">
              <RiArrowRightSLine />
            </span>
          </Link>
          <div
            className="social-icons"
            sx={{
              variant: "variants.socialIcons",
            }}
          >
            {sIcons}
          </div>
        </div>
        <div>
          {Image ? (
            <GatsbyImage
              image={Image}
              alt={frontmatter.title + " - Featured image"}
              className="cover"
            />
             ) : (
               ""
          )}
        </div>
      </div>
      <BlogListHome data={posts} />
    </Layout>
  )
}

export default HomePage
